import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgMyTasksGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "mytasksgradientsolid", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { clipPath: "url(#my-tasks-c)", children: [
    /* @__PURE__ */ jsx("path", { d: "M13.7397 2.81822H6.49508C5.44629 2.81822 4.59607 3.66844 4.59607 4.71723V10.3539C4.59607 11.4027 5.44628 12.253 6.49508 12.253H13.6074C14.6388 12.253 15.4817 11.4297 15.5059 10.3985L15.6382 4.7618C15.6632 3.69585 14.8059 2.81822 13.7397 2.81822Z", fill: "var(--motion-icon-gradient-shade-dark, color(display-p3 .6039 .6784 .9255))" }),
    /* @__PURE__ */ jsx("path", { d: "M2.48252 6.70281C2.51832 5.51825 3.48893 4.57652 4.67404 4.57652H11.699C12.9257 4.57652 13.9137 5.58297 13.8911 6.80948L13.7854 12.5416C13.7633 13.7365 12.7884 14.6937 11.5932 14.6937H4.5008C3.26396 14.6937 2.27192 13.6712 2.30928 12.4349L2.48252 6.70281Z", fill: "var(--motion-icon-gradient-shade-light, color(display-p3 .9647 .9765 1))" }),
    /* @__PURE__ */ jsx("path", { d: "M7.78198 11.5782C4.86128 11.5782 4.84739 13.0345 4.59601 14.6902L11.3675 14.6901C11.2317 13.0345 11.3675 11.499 7.78198 11.5782Z", fill: "var(--motion-icon-gradient-shade-dark, color(display-p3 .6039 .6784 .9255))" }),
    /* @__PURE__ */ jsx("path", { d: "M8.03616 9.93955C8.83903 9.93955 9.5124 9.29448 9.54019 8.49875C9.56798 7.70302 8.93965 7.05795 8.13679 7.05795C7.33392 7.05795 6.66055 7.70302 6.63276 8.49875C6.60497 9.29448 7.2333 9.93955 8.03616 9.93955Z", fill: "var(--motion-icon-gradient-shade-dark, color(display-p3 .6039 .6784 .9255))" }),
    /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.15425 6.55826C7.07917 6.55826 6.17033 7.42314 6.13277 8.49875C6.09521 9.57437 6.94364 10.4392 8.01872 10.4392C9.0938 10.4392 10.0026 9.57437 10.0402 8.49875C10.0778 7.42314 9.22932 6.55826 8.15425 6.55826ZM7.13277 8.49875C7.15078 7.9829 7.5887 7.55765 8.11935 7.55765C8.65 7.55765 9.05821 7.9829 9.0402 8.49875C9.02218 9.0146 8.58427 9.43985 8.05362 9.43985C7.52297 9.43985 7.11475 9.0146 7.13277 8.49875Z", fill: "url(#my-tasks-a)" }),
    /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.2813 12.657C15.2593 12.3712 15.9806 11.4791 16.0057 10.4102L16.1381 4.77353C16.1697 3.42692 15.0867 2.31822 13.7397 2.31822H6.49509C5.37502 2.31822 4.43424 3.08581 4.17007 4.12365C2.95273 4.35431 2.02151 5.40509 1.98275 6.68771L1.80951 12.4198C1.76363 13.9381 2.9819 15.1937 4.50081 15.1937H11.5932C13.0254 15.1937 14.2001 14.0745 14.2813 12.657ZM5.25108 4.07652H11.699C13.2054 4.07652 14.4188 5.31248 14.391 6.8187L14.3034 11.5678C14.714 11.3318 14.9941 10.8933 15.006 10.3868L15.1383 4.75007C15.1568 3.96478 14.5252 3.31822 13.7397 3.31822H6.49509C5.95334 3.31822 5.48351 3.62615 5.25108 4.07652ZM2.98229 6.71792C3.00993 5.80349 3.7592 5.07652 4.67404 5.07652H11.699C12.6459 5.07652 13.4087 5.85345 13.3912 6.80026L13.2855 12.5324C13.27 13.3737 12.6425 14.0623 11.8324 14.1769C11.813 13.9162 11.7896 13.6471 11.7478 13.3958C11.679 12.9814 11.5528 12.5526 11.274 12.1771C10.6993 11.4028 9.62655 11.0388 7.79386 11.0785C6.26537 11.0795 5.32331 11.4629 4.78134 12.2366C4.52566 12.6016 4.39007 13.0132 4.30095 13.4133C4.24753 13.6531 4.20703 13.9092 4.17004 14.1614C3.37553 14.0041 2.78364 13.2912 2.80906 12.4501L2.98229 6.71792ZM10.4628 12.7901C10.1756 12.4033 9.52506 12.0391 7.77559 12.0778L7.76452 12.0781C6.37559 12.0781 5.85199 12.4227 5.59465 12.7901C5.45035 12.9961 5.35279 13.259 5.27734 13.5977C5.23526 13.7865 5.20328 13.9815 5.17217 14.1905L10.8272 14.1904C10.8109 13.9797 10.7923 13.7838 10.7606 13.5927C10.7046 13.256 10.6152 12.9955 10.4628 12.7901Z", fill: "url(#my-tasks-b)" })
  ] }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("linearGradient", { id: "my-tasks-a", x1: 7.97788, y1: 7.47557, x2: 7.97788, y2: 14.1458, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "var(--motion-icon-gradient-bg-top, color(display-p3 .5176 .6078 .898))" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--motion-icon-gradient-bg-bottom, color(display-p3 .2392 .3804 .8667))" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "my-tasks-b", x1: 7.97788, y1: 7.47557, x2: 7.97788, y2: 14.1458, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "var(--motion-icon-gradient-bg-top, color(display-p3 .5176 .6078 .898))" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--motion-icon-gradient-bg-bottom, color(display-p3 .2392 .3804 .8667))" })
    ] }),
    /* @__PURE__ */ jsx("clipPath", { id: "my-tasks-c", children: /* @__PURE__ */ jsx("rect", { width: 16, height: 16, transform: "translate(0.962372 0.755859)" }) })
  ] })
] });
const ForwardRef = forwardRef(SvgMyTasksGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "MyTasksGradientSolidIcon";
