import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgAllTasksGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none", "data-icon": "alltasksgradientsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fill: "var(--motion-icon-gradient-shade-dark, color(display-p3 .6039 .6784 .9255))", d: "M12.777 2.062H5.533c-1.05 0-1.9.85-1.9 1.9v5.636c0 1.049.85 1.9 1.9 1.9h7.112a1.899 1.899 0 0 0 1.899-1.855l.132-5.637a1.899 1.899 0 0 0-1.899-1.944Z" }),
  /* @__PURE__ */ jsx("path", { fill: "url(#all-tasks-a)", fillRule: "evenodd", d: "M3.134 3.961a2.399 2.399 0 0 1 2.399-2.399h7.244a2.399 2.399 0 0 1 2.399 2.456l-.133 5.636a2.4 2.4 0 0 1-2.398 2.343H5.533a2.399 2.399 0 0 1-2.4-2.399V3.961Zm2.399-1.399c-.773 0-1.4.627-1.4 1.4v5.636c0 .773.627 1.4 1.4 1.4h7.112c.76 0 1.38-.607 1.399-1.367l.132-5.637a1.399 1.399 0 0 0-1.399-1.432H5.533Z", clipRule: "evenodd" }),
  /* @__PURE__ */ jsx("path", { fill: "var(--motion-icon-gradient-shade-light, color(display-p3 .9647 .9765 1))", d: "M1.52 5.947a2.193 2.193 0 0 1 2.192-2.126h7.025a2.192 2.192 0 0 1 2.192 2.233l-.106 5.732a2.192 2.192 0 0 1-2.192 2.152H3.538a2.192 2.192 0 0 1-2.191-2.259l.173-5.732Z" }),
  /* @__PURE__ */ jsx("path", { fill: "url(#all-tasks-b)", fillRule: "evenodd", d: "M1.02 5.932A2.693 2.693 0 0 1 3.712 3.32h7.025a2.693 2.693 0 0 1 2.692 2.742l-.106 5.732a2.692 2.692 0 0 1-2.692 2.643H3.538a2.692 2.692 0 0 1-2.69-2.774l.172-5.732ZM3.712 4.32c-.915 0-1.664.727-1.692 1.641l-.173 5.732a1.693 1.693 0 0 0 1.691 1.744h7.093c.922 0 1.675-.739 1.692-1.662l.106-5.732a1.693 1.693 0 0 0-1.692-1.723H3.712Z", clipRule: "evenodd" }),
  /* @__PURE__ */ jsx("path", { stroke: "url(#all-tasks-c)", strokeLinecap: "round", strokeLinejoin: "round", d: "m4.732 9.038 1.527 1.627 3.257-3.36" }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("linearGradient", { id: "all-tasks-a", x1: 9.178, x2: 9.178, y1: 2.062, y2: 11.497, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "var(--motion-icon-gradient-bg-top, color(display-p3 .5176 .6078 .898))" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--motion-icon-gradient-bg-bottom, color(display-p3 .2392 .3804 .8667))" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "all-tasks-b", x1: 7.124, x2: 7.124, y1: 3.821, y2: 13.938, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "var(--motion-icon-gradient-bg-top, color(display-p3 .5176 .6078 .898))" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--motion-icon-gradient-bg-bottom, color(display-p3 .2392 .3804 .8667))" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "all-tasks-c", x1: 7.124, x2: 7.124, y1: 7.305, y2: 10.665, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "var(--motion-icon-gradient-bg-top, color(display-p3 .5176 .6078 .898))" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--motion-icon-gradient-bg-bottom, color(display-p3 .2392 .3804 .8667))" })
    ] })
  ] })
] });
const ForwardRef = forwardRef(SvgAllTasksGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "AllTasksGradientSolidIcon";
